<template>
  <base-section
    id="news"
    space="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <news-card
            v-for="(article, i) in articles"
            :key="i"
            :divider="i + 1 < articles.length"
            class="mb-12"
            v-bind="article"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNewsAlt',

    components: {
      NewsArchives: () => import('@/components/news/Archives'),
      NewsCard: () => import('@/components/news/Card'),
      NewsCategories: () => import('@/components/news/Categories'),
      NewsSearch: () => import('@/components/news/Search'),
      NewsTags: () => import('@/components/news/Tags'),
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-text',
          readMore: true,
          date: '',
          title: 'RONGHAO Brochure',
          newspaper: 'RONGHAO',
          link: 'https://drive.google.com/file/d/1HH5ptIVQqWe2YR6JTM5jPDKRz5zHKFh9/view?usp=sharing',
          src: require('@/assets/download_brochure.jpg'),
        },
      ],
    }),
  }
</script>
